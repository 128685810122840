<!-- 关联优惠券 -->

<template>
  <w-dialog ref="dialogRef" title="关联优惠券" width="40%" top="15vh" :modalClick="false" :hideFooter="true">
    <el-row :gutter="10">
      <el-col :span="12">
        <el-select v-model="g_id" clearable filterable placeholder="请选择优惠券">
          <el-option :label="item.g_name_type_text" :value="item.g_id" v-for="item in couponOptions" :key="item.g_id">
          </el-option>
        </el-select>
      </el-col>

      <el-col :span="6">
        <el-input v-model.trim="num" clearable placeholder="请输入数量"></el-input>
      </el-col>

      <el-col :span="2">
        <el-button type="primary" round @click="onIncreaseBtn(1)"
          v-if="authData.indexOf('n_iUSCjadHfQADYlMxnDA13X12s5H1') != -1">添加</el-button>
      </el-col>
    </el-row>

    <div class="coupon-lists">
      <div v-if="couponLists.length == 0" class="empty">暂无数据</div>
      <div v-else v-for="(item, index) in couponLists" :key="index" class="lists">
        <div>
          <span>{{ item.g_name }}</span>
          <span style="margin-left: 20px;">{{ item.gift_send_type_text }}</span>
        </div>
        <div>
          <span class="coupon-number">{{ item.stg_num }}张</span>
          <span class="remove-btn" @click="onIncreaseBtn(2, item)"
            v-if="authData.indexOf('n_iUSCjadHfQADYlMxnDA13X12s5H1') != -1">移除</span>
        </div>
      </div>
    </div>
  </w-dialog>
</template>

<script>
  import { ref, reactive, toRefs, watch, computed, } from "vue";
  import { ElMessage } from "element-plus";
  import { ScenicApi } from "@/plugins/api.js";
  import { useStore } from "vuex";

  export default {
    name: "AddClassifiPage",
    components: {},
    emits: ["submit"],
    setup(props, { emit }) {
      const dialogRef = ref(null);  // 对话框对象
      const state = reactive({
        g_id: '',  // 优惠券
        num: '',  // 数量
        couponLists: [],
        couponOptions: [],
        currentDatas: '',  // 当前行
      });
      const store = useStore();
      const menuTokens = computed(() => store.state.menuToken.menuTokens);
      const authData = ref([]);
      watch(
        () => menuTokens.value,
        (data) => {
          if (data.length) {
            authData.value = data;
          }
        },
        {
          deep: true,
          immediate: true,
        }
      );
      /**
       * 
       * 显示对话框
       * 
       * */
      function openDialog(item) {
        state.currentDatas = item;
        dialogRef.value.isLoading = false;
        state.g_id = '';
        state.num = '';
        getTicketGift();
        getGiftList();
        dialogRef.value.show();
      }
      /**
       * 
       * 关闭对话框
       * 
       * */
      function closeDialog() {
        dialogRef.value.close();
      }
      /**
       * 
       * 获取优惠券选择数据
       * 
       * */
      const getGiftList = () => {
        ScenicApi.getGiftList({ s_id: state.currentDatas.s_id }).then((res) => {
          if (res.Code === 200) {
            state.couponOptions = res.Data ? res.Data : [];
          } else {
            let msg = res.Message ? res.Message : "获取数据失败！";
            ElMessage.error(msg);
          }
        });
      }
      /**
       * 
       * 获取已关联优惠券列表数据
       * 
       * */
      const getTicketGift = () => {
        ScenicApi.getTicketGift({ st_id: state.currentDatas.st_id }).then((res) => {
          if (res.Code === 200) {
            state.couponLists = res.Data ? res.Data : [];
          } else {
            let msg = res.Message ? res.Message : "获取数据失败！";
            ElMessage.error(msg);
          }
        });
      }
      /**
       * 
       * 确认按钮
       * 
       * */
      function onIncreaseBtn(type, row) {
        if (type == 1) {
          // 新增
          if (state.g_id == '') {
            ElMessage.error("请选择优惠券！");
            return false;
          } else if (state.num == '') {
            ElMessage.error("请输入数量！");
            return false;
          }
        }
        let params = {
          st_id: state.currentDatas.st_id,
          g_id: type == 1 ? state.g_id : row.g_id,
          num: type == 1 ? state.num : row.stg_num,
          type: type
        };
        ScenicApi.setticketgift(params).then((res) => {
          if (res.Code === 200) {
            state.num = '';
            state.g_id = '';
            getTicketGift();
            emit("submit");
            ElMessage.success("操作成功！");
          } else {
            let msg = res.Message ? res.Message : "操作失败！";
            ElMessage.error(msg);
          }
        });
      }

      return {
        ...toRefs(state),
        dialogRef,
        openDialog,
        closeDialog,
        onIncreaseBtn,
        getTicketGift,
        getGiftList,
        authData,
        store,
        menuTokens,
      };
    },
  };
</script>

<style lang="scss">
  .barcode-content {
    margin-top: 20px;
    flex-flow: wrap;
  }

  .barcode-text {
    margin-right: 10px;
  }

  .circle-num {
    padding: 10px 16px;
    background: var(--menu-bg-color);
    margin-top: 10px;
    border-radius: 10px;
  }

  .circle-close {
    cursor: pointer;
    position: absolute;
    right: -4px;
    top: 4px;
  }

  .empty-text {
    text-align: center;
    margin: 40px 0;
  }

  .content-title {
    margin-bottom: 5px;
  }

  .el-select {
    width: 100%;
  }

  .coupon-lists {
    margin: 15px 0;
  }

  .lists {
    display: flex;
    justify-content: space-between;
    background-color: #f7f7f7;
    margin-bottom: 10px;
    padding: 10px 16px;
  }

  .coupon-number {
    margin-right: 20px;
    color: var(--text-third-color),
  }

  .remove-btn {
    color: var(--theme-color);
    cursor: pointer;
  }

  .empty {
    text-align: center;
    padding-top: 20px;
  }
</style>
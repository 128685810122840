<template>
  <div class="net-config-dialog">
    <w-dialog
      ref="dialogRef"
      title="入账配置"
      width="60%"
      top="15vh"
      @wConfirm="handleSure"
    >
      <div class="add-container">
        <div class="ticket-title">{{ title }}</div>
        <div
          class="action-btn"
          @click="handleAdd"
          v-if="accountArr.length < accountOptions.length"
        >
          增加一行
        </div>
      </div>
      <el-divider></el-divider>
      <div class="net-config-content">
        <template v-if="accountArr.length">
          <template v-if="type == 'hotel'">
            <el-form ref="formRef" label-position="top">
              <template v-for="(el, index) in accountArr" :key="el.hd_mpid">
                <el-row :gutter="20" class="net-config-row">
                  <el-col :span="8">
                    <el-form-item>
                      <el-select
                        v-model="el.hd_mpid"
                        placeholder="请选择入账账号"
                        clearable
                      >
                        <el-option
                          v-for="item in accountOptions"
                          :disabled="
                            disabledArr.indexOf(item.mp_id) != -1 &&
                            accountOptions.length > 1
                          "
                          :label="item.mp_mch_name"
                          :value="item.mp_id"
                          :key="item.mp_id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item>
                      <el-input
                        v-model="el.hd_percent"
                        autocomplete="off"
                        placeholder="请输入分账比例"
                        type="number"
                        min="0"
                        clearable
                        ><template #append>%</template></el-input
                      >
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <div class="delete-btn" v-if="accountArr.length > 1">
                      <span class="action-btn" @click="handleDelete(el, index)"
                        >删除</span
                      >
                    </div>
                  </el-col>
                </el-row>
              </template>
            </el-form>
          </template>
          <template v-else>
            <el-form ref="formRef" label-position="top">
              <template v-for="(el, index) in accountArr" :key="el.std_mpid">
                <el-row :gutter="20" class="net-config-row">
                  <el-col :span="8">
                    <el-form-item>
                      <el-select
                        v-model="el.std_mpid"
                        placeholder="请选择入账账号"
                        clearable
                      >
                        <el-option
                          :disabled="
                            disabledArr.indexOf(item.mp_id) != -1 &&
                            accountOptions.length > 1
                          "
                          :label="item.mp_mch_name"
                          :value="item.mp_id"
                          v-for="item in accountOptions"
                          :key="item.mp_id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item>
                      <el-input
                        v-model="el.std_percent"
                        autocomplete="off"
                        placeholder="请输入分账比例"
                        type="number"
                        min="0"
                        clearable
                        ><template #append>%</template></el-input
                      >
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <div class="delete-btn" v-if="accountArr.length > 1">
                      <span class="action-btn" @click="handleDelete(el, index)"
                        >删除</span
                      >
                    </div>
                  </el-col>
                </el-row>
              </template>
            </el-form></template
          >
        </template>
        <template v-else><el-empty description="暂无入账配置" /></template>
      </div>
    </w-dialog>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { HotelApi, BasicApi, ScenicApi } from "@/plugins/api.js";
import { ElMessage } from "element-plus";
export default {
  emits: ["submit"],
  setup(props, { emit }) {
    const dialogRef = ref(null);
    const accountArr = ref([]);
    const disabledArr = computed(() => {
      const result = [];
      accountArr.value.length &&
        accountArr.value.forEach((el) => {
          result.push(type.value == "hotel" ? el.hd_mpid : el.std_mpid);
        });
      return result;
    });
    const title = ref("");
    const type = ref(null);
    function getDetail() {
      accountArr.value = [];
      if (type.value == "hotel") {
        HotelApi.getHotelAccount({
          hd_hid: id.value,
        }).then((res) => {
          if (res.Code === 200) {
            res.Data &&
              res.Data.length &&
              res.Data.forEach((el) => {
                accountArr.value.push({
                  hd_mpid: el.hd_mpid,
                  hd_percent: el.hd_percent,
                });
              });
          } else {
            let msg = res.Message || "获取酒店分成比例失败！";
            ElMessage.error(msg);
          }
        });
      } else if (type.value == "sticket") {
        ScenicApi.getTicketAccount({
          std_stid: id.value,
        }).then((res) => {
          if (res.Code === 200) {
            res.Data &&
              res.Data.length &&
              res.Data.forEach((el) => {
                accountArr.value.push({
                  std_mpid: el.std_mpid,
                  std_percent: el.std_percent,
                });
              });
          } else {
            let msg = res.Message || "获取门票分成比例失败！";
            ElMessage.error(msg);
          }
        });
      }
    }
    const accountOptions = ref([]);
    function getAccountOptions() {
      BasicApi.accountOptions().then((res) => {
        if (res.Code === 200) {
          accountOptions.value = res.Data || [];
        } else {
          let msg = res.Message || "获取商户号支付配置失败！";
          ElMessage.error(msg);
        }
      });
    }
    const id = ref(null);
    function openDialog(sendType, sendData) {
      accountArr.value = [];
      type.value = sendType;
      if (sendType == "hotel") {
        title.value = sendData.h_name;
        id.value = sendData.h_id;
      } else if (sendType == "sticket") {
        title.value = sendData.st_name;
        id.value = sendData.st_id;
      }
      getAccountOptions();
      getDetail();
      dialogRef.value.show();
    }
    function closeDialog() {
      dialogRef.value.close();
    }
    const formRef = ref(null);
    function handleDelete(row, index) {
      accountArr.value.splice(index, 1);
    }
    function handleAdd() {
      let percent = 0;

      accountArr.value.length &&
        accountArr.value.forEach((el) => {
          if (type.value == "hotel") {
            percent += +el.hd_percent;
          } else {
            percent += +el.std_percent;
          }
        });
      if (percent == 100) {
        ElMessage.warning(
          "入账配置比例总和只能为100%，请先修改已配置的入账比例！"
        );
        return false;
      }
      if (type.value == "hotel") {
        accountArr.value.push({
          hd_mpid: "",
          hd_percent: "",
        });
      } else {
        accountArr.value.push({
          std_mpid: "",
          std_percent: "",
        });
      }
    }
    function closeDialogLoading() {
      dialogRef.value.isLoading = false;
    }
    function handleSure() {
      dialogRef.value.isLoading = true;
      const data = JSON.parse(JSON.stringify(accountArr.value));
      const lastData = { divide: data };
      let percent = 0;

      if (type.value == "hotel") {
        for (var i = 0; i < data.length; i++) {
          if (!data[i].hd_mpid || !data[i].hd_percent) {
            ElMessage.warning("请将所填信息补充完整！");
            closeDialogLoading()
            return false;
          }
          percent += +data[i].hd_percent;
        }
        lastData["hd_hid"] = id.value;
      } else {
        for (var j = 0; j < data.length; j++) {
          if (!data[j].std_mpid || !data[j].std_percent) {
            ElMessage.warning("请将所填信息补充完整！");
            closeDialogLoading()
            return false;
          }
          percent += +data[j].std_percent;
        }
        lastData["std_stid"] = id.value;
      }
      if (percent != 100) {
        ElMessage.warning("入账配置比例总和只能为100%！");
        closeDialogLoading()
        return false;
      }
      emit("submit", lastData);
    }
    return {
      formRef,
      handleSure,
      openDialog,
      dialogRef,
      closeDialog,
      title,
      accountOptions,
      handleDelete,
      handleAdd,
      accountArr,
      disabledArr,
      type,
      closeDialogLoading,
    };
  },
};
</script>

<style lang="scss">
.net-config-dialog {
  .el-dialog__body {
    .net-config-content {
      height: 400px;
      overflow-y: auto;
    }
  }
  .el-divider--horizontal {
    border-top: 1px #eee solid;
  }
  .action-btn {
    color: var(--theme-color);
    cursor: pointer;
    &:hover {
      font-weight: bold;
    }
  }
  .add-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ticket-title {
      font-size: 16px;
      color: var(--text-color);
      font-weight: bold;
    }
  }
  .net-config-row {
    border: 2px dashed #eee;
    margin: 0 0 16px 0 !important;
    padding: 16px 10px;
    .el-form-item {
      margin-bottom: 0;
      .el-input-group__append {
        background-color: var(--search-uncheck-bg-color);
        border: none;
      }
    }
    .delete-btn {
      text-align: right;
      height: 40px;
      line-height: 40px;
    }
  }
}
</style>
